import { createAction, props } from '@ngrx/store';
import {
  CountryDropDownListItemDto,
  DropDownListItemDto,
} from '../../shared/models/menus/drop-down-list-item.model';
import { AcctGlaccountDto } from '../../shared/models/accounting/acctglaccount.model';
import { GroupForAnalyticDto } from '../../shared/models/menus/group-for-analytic.model';
import { SysCorrLanguageDto } from '../../shared/models/system/sys-corr-language';
import { SysMembershipPlanTypeDto } from '../../shared/models/system/sys-membership-plan-type';
import { SysBillingFrequencyDto } from '../../shared/models/system/sys-billing-frequency';
import { CoAcctDiscountCouponDto } from '../../shared/models/accounting/acct-discount-coupon';
import { AcctInvoiceStyleDto } from '../../shared/models/accounting/acct-invoice-style';
import { SysDisciplineDto } from '../../shared/models/system/sys-discipline.model';

export const setClinicGroupsDropDownList = createAction(
  '[Inital Data] Load Clinic Groups',
  props<{ clinicGroupDropdown: DropDownListItemDto[] | null }>()
);
export const setMeasurementUnitsDropDownListItem = createAction(
  '[Inital Data] Load Measure Units',
  props<{ measurementUnitsDropdown: DropDownListItemDto[] | null }>()
);
export const setAcctGlaccounts = createAction(
  '[Inital Data] Load AcctGlaccounts',
  props<{ acctGlaccounts: AcctGlaccountDto[] | null }>()
);
export const setGroupForAnalytics = createAction(
  '[Inital Data] Load GroupForAnalytics',
  props<{ groupForAnalytics: GroupForAnalyticDto[] | null }>()
);
export const setSysDisciplinesDropDownListItem = createAction(
  '[Inital Data] Load Disciplines Drop Down List',
  props<{ disciplinesDropDown: DropDownListItemDto[] | null }>()
);
export const setSysDisciplines = createAction(
  '[Inital Data] Load Sys Disciplines DTO List',
  props<{ sysDisciplines: SysDisciplineDto[] | null }>()
);
export const setCitiesDropDown = createAction(
  '[Inital Data] Load Cities DropDown',
  props<{ citiesDropDown: DropDownListItemDto[] | null }>()
);
export const setCountriesDropDown = createAction(
  '[Inital Data] Load Countries DropDown',
  props<{ countriesDropDown: CountryDropDownListItemDto[] | null }>()
);
export const setSalutationDropDown = createAction(
  '[Inital Data] Load SysSalutation DropDown',
  props<{ salutationDropDown: DropDownListItemDto[] | null }>()
);
export const setFranchiseCorrLanguages = createAction(
  '[Inital Data] Load Franchise Sys CorrLanguages',
  props<{ franchiseCorrLanguages: SysCorrLanguageDto[] | null }>()
);
export const setSysCorrLanguages = createAction(
  '[Inital Data] Load Sys CorrLanguages',
  props<{ sysCorrLanguages: SysCorrLanguageDto[] | null }>()
);
export const setMembershipPlanTypes = createAction(
  '[Inital Data] Load Membership Plan Types',
  props<{ membershipPlanTypes: SysMembershipPlanTypeDto[] | null }>()
);
export const setMembershipRenewalOptions = createAction(
  '[Inital Data] Load Membership Renewal Options',
  props<{ membershipRenewalOptions: DropDownListItemDto[] | null }>()
);
export const setBillingFrequencies = createAction(
  '[Inital Data] Load Billing Frequencies',
  props<{ billingFrequencies: SysBillingFrequencyDto[] | null }>()
);
export const setMembershipCoAcctDiscountCoupons = createAction(
  '[Inital Data] Load Membership Discount Coupons',
  props<{ membershipCoAcctDiscountCoupons: CoAcctDiscountCouponDto[] | null }>()
);
export const setClinicGroupFranchiseAcctInvoiceStyles = createAction(
  '[Inital Data] Load ClinicGroup Franchise Acct Invoice Styles',
  props<{
    clinicGroupFranchiseAcctInvoiceStyles: AcctInvoiceStyleDto[] | null;
  }>()
);
